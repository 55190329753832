
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { FormaPagamentoService } from "@/core/services/financeiro";
import { FormaPagamento } from "@/core/models/financeiro";
import * as jsonpatch from 'fast-json-patch';
import { TratarErroApi } from "@/assets/scripts/helper";

@Component
export default class CadastroTipoDocumento extends Vue {
  @Prop() public item!: FormaPagamento;
  @Prop() public value!: string;

  service = new FormaPagamentoService();
  valid = true;
  dialog = false;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement
  }

  @Watch('item') 
  Item(){
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  observer! : jsonpatch.Observer<FormaPagamento>; 

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Salvar() {
    if (this.$refs.form.validate()) {
    
      let pacthModel = jsonpatch.generate(this.observer);
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
      (err) => TratarErroApi(err)
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
