
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Contrato } from '@/core/models/cadastros';
import { Receita,EnumTipoReceita, Encargo, ValoresAdicionais, TipoAmortizacao } from '@/core/models/financeiro';
import { IntervaloReajusteService, ParametroFinanceiroService, ReceitaService, TipoAmortizacaoService, TipoDocumentoService} from '@/core/services/financeiro';
import { ContratoService } from '@/core/services/cadastros/ContratoService';
import { EmpreendimentoService, PessoaService, SituacaoContratoService, TipoAditamentoContrato, TipoIndiceService } from '@/core/services/cadastros';
import Pessoa from '@/core/models/cadastros/Pessoa';
import { EnumSitucaoContrato } from '@/core/models/cadastros/contrato/SituacaoContrato';
import { TratarErroApi } from '@/assets/scripts/helper';
  
@Component 
export default class AditarContrato extends Vue {
  @Prop() public item!: Contrato;
  @Prop() public value!: string;

  service = new ContratoService();
  itemPessoa:any = new Pessoa();
  valoresAdicionaisParcela = new ValoresAdicionais();

  receitaGerenciada = new Receita();
  receitaIntermediaria = new Receita(5); 
  receitaSaldoDevedor = new Receita(1);
  receitaAditamento = new Receita(4);
  receitaEntrada = new Receita(3);

  pessoa = new Pessoa(); 

  dialogMultiLotes:boolean = false;
  dialogGerencialPrice:boolean = false;
  dialogCorretor:boolean = false;
  dialogGerencial:boolean = false;
  dialogPessoa:boolean = false;
  dialog:boolean = false;
  overlay:boolean = false;
  valid:boolean = true;

  totalEncargos:number = 0;
  itemIndex: number = -1;
  e13: number = 1;
  tab =  0;
         
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  };

  tiposAditamentos = [];
  listarTipoIndice = [];
  listaGateway:any = [];
  pessoas = [];
  listasSituacoes = [];
  tiposAmortizacao = [];
  tiposIntervalos = [];
  listaTiposDocumentos:any = [];
  listaEmpreendimentos = [];
  listaGrupos = [];
  listaUnidades = []; 
 
  headerClientes: any[] = [ 
    { text: "", value: "actions" },
    { text: "Nome", value: "clienteId" }, 
  ]; 

  headerCorretores: any[] = [
    { text: "", value: "actions" },
    { text: "Nome", value: "corretorId" },
  ];
   
  ObterMesAno(){
    let dataReajuste = new Date(this.item.data).yyyymmdd();    

    const partes =  new Date(this.item.data).ddmmyyyy().split('/');
    const data = new Date(Number(partes[2]), Number(partes[1]) - 1, Number(partes[0]));
    const mes = data.getMonth() + 1;

    let ano = new Date(dataReajuste).getFullYear();
    this.item.anoPrimeiroReajuste = ano;
    this.item.mesReajuste = mes;
  } 
 
  @Watch("item")
  Item() {
  if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  } 
  
  @Watch("value")
  Value() { 
    this.dialog = this.value ? true : false; 
  } 
  
  @Watch('dialog')
  Dialog() {
    if (!this.dialog) {
      this.$emit('fechou');
    }else{
      this.GetEncargosContrato();
      this.ObterMesAno();
      this.ObterParametroPorId(this.item.empresaId), this.ObterGrupos(), this.ObterUnidades();
    }  
  } 

  ObterParametroPorId(id){
    new ParametroFinanceiroService().ObterParametroEmpresaPorId(id, 'Gatways.Tipo').then(
      (res) => { 
        this.listaGateway = res.data.gatways;
        this.valoresAdicionaisParcela = res.data.valoresAdicionaisParcela;
      }, 
      (err) => {
        this.item.encargoFinanceiro = new Encargo();
        this.valoresAdicionaisParcela = new ValoresAdicionais();
      }
    )
  }

  GetEncargosContrato(){
    new ReceitaService().EncargosContrato(this.item.id).then(
      (res) => {
        this.totalEncargos = res.data;
        this.receitaSaldoDevedor.valor = Number((this.item.saldoGeral + this.totalEncargos).toFixed(2));
        this.item.valor = Number((this.item.saldoGeral + this.totalEncargos).toFixed(2));
      },
      (err) => { 
        TratarErroApi(err); 
      }
    )
  }
 
  Confirmar() {
    this.$refs.form.validate();

    if (!this.Validacoes()) { 
       this.$swal({
        title: "Atenção!",
        text: "Tem certeza que deseja Aditar esse contrato?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.Salvar();
        },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading(),
      }) 
    }
  }

  Validacoes(){
    if(!this.item.tipoAditamentoId  || !this.item.situacaoId || !this.item.data || !this.item.mesReajuste || !this.item.tipoIndiceId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.e13 = 3;
      return true;
    }

    if(this.item.tipoAmortizacaoId != 1 && this.item.jurosAmortizacao <= 0){
      this.$swal("Aviso", "O Juros da tabela precisa ser maior que 0", "warning");
      this.e13 = 3;
      return true;
    }
  }
 
  Salvar(){
    this.overlay = !this.overlay;
    this.item.receitas = [];  

    let ReceitasAdicionais = [this.receitaSaldoDevedor, this.receitaAditamento, this.receitaIntermediaria, this.receitaEntrada];
    let modelo = this.item.PrepararContrato(this.item, ReceitasAdicionais);

    this.service.AditarContrato(this.item.id, modelo).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
      (err) => {
        TratarErroApi(err);
        this.overlay = false;
      }
    );  
  } 
 
  Close() {
    this.Reset();
    this.overlay = false;
    this.dialog = false;
  }

  ObterReceita(receita){
    this.CarregarTipoReceita(receita.tipoId);
    this.receitaGerenciada = receita; 

    if(this.item.tipoAmortizacaoId == TipoAmortizacao.Price && receita.tipoId == EnumTipoReceita.Titulo){
      this.dialogGerencialPrice = true;
    } else {
      this.dialogGerencial = true;
    }
  }

  CarregarTipoReceita(tipoId){
    switch (tipoId) {
      case EnumTipoReceita.Titulo:
          this.ConfigurarReceita(this.receitaSaldoDevedor, "Financiamento");
        break;

      case EnumTipoReceita.TituloEntrada:
          this.ConfigurarReceita(this.receitaEntrada, "Entrada");
        break;

      case EnumTipoReceita.TituloAditamento:
          this.ConfigurarReceita(this.receitaAditamento, "Honorários");
        break;

        case EnumTipoReceita.TituloIntermediaria:
          this.ConfigurarReceita(this.receitaIntermediaria, "Intermediária");
        break;
      }  
      
  } 

  ConfigurarReceita(receita, titulo) {
    receita.melhorDia = this.item.melhorDia;
    receita.dataInicialParcelas = new Date().yyyymmdd();
    //receita.empresaId = this.item.empresaId;
    receita.tipoAmortizacaoId = this.item.tipoAmortizacaoId;
    receita.jurosAmortizacao = this.item.jurosAmortizacao;
    receita.valoresAdicionaisParcela = this.valoresAdicionaisParcela;
    receita.tipoDocumentoId = this.listaTiposDocumentos[0].id;
    receita.titulo = titulo;
  };

  /* CLIENTE */
  AdicionarCliente(){
    const clienteId = this.itemPessoa.clienteId;
    const clienteExistente = this.item.clientes.find((x, index) => x.clienteId === clienteId && index !== this.itemIndex);
    
    if (clienteExistente){
      return this.$swal("Aviso", "Este cliente já foi adicionada na lista.", "warning");
    }
    
    if (this.itemIndex > -1) {
      Object.assign(this.item.clientes[this.itemIndex], this.itemPessoa);
    }else{
      this.item.clientes.push(this.itemPessoa);
    }
    this.FecharDialogCliente();
  }
 
  ExcluirCliente(item){ 
    this.$swal({ 
      title: "Atenção!", 
      text: "Tem certeza que deseja excluir o registro atual?", 
      icon: "question", 
      showCancelButton: true, 
      confirmButtonText: "Sim", 
      cancelButtonText: "Não", 
      showCloseButton: true, 
    }).then((result) => { 
      if (result.value) { 
        const index = this.item.clientes.indexOf(item); 
        this.item.clientes.splice(index, 1); 
        this.$swal("Aviso", "Registro excluído com sucesso!", "success"); 
      } 
    }); 
  } 

  FecharDialogCliente(){
    this.dialogPessoa = false;
    this.itemPessoa = new Pessoa();
    this.itemIndex = -1;
  }

  EditarCliente(item){
    this.itemPessoa = Object.assign({}, item);
    this.itemIndex = this.item.clientes.indexOf(item);
    this.dialogPessoa = true; 
  }

  /* CORRETOR */
  AdicionarCorretor(){
    const corretorId = this.itemPessoa.corretorId;
    const corretorExistente = this.item.corretores.find((x, index) => x.corretorId === corretorId && index !== this.itemIndex);
    
    if (corretorExistente) 
      return this.$swal("Aviso", "Este corretor já foi adicionada na lista.", "warning");
      
    if (this.itemIndex > -1) {
      Object.assign(this.item.corretores[this.itemIndex], this.itemPessoa);
    } else {
      this.item.corretores.push(this.itemPessoa);
    }
    this.CloseCorretor();
  }

  EditarCorretor(item){
    this.itemPessoa = Object.assign({}, item);
    this.itemIndex = this.item.corretores.indexOf(item);
    this.dialogCorretor = true;
  }
 
  ExcluirCorretor(item){
    this.$swal({ 
      title: "Atenção!", 
      text: "Tem certeza que deseja excluir o registro atual?", 
      icon: "question", 
      showCancelButton: true, 
      confirmButtonText: "Sim", 
      cancelButtonText: "Não", 
      showCloseButton: true, 
    }).then((result) => { 
      if (result.value) { 
        const index = this.item.corretores.indexOf(item); 
        this.item.corretores.splice(index, 1); 
        this.$swal("Aviso", "Registro excluído com sucesso!", "success"); 
      } 
    }); 
  }

  CloseCorretor(){ 
    this.dialogCorretor = false;
    this.itemPessoa = new Pessoa();
    this.itemIndex = -1;
  }
  
  @Watch('receitaEntrada.valor')
  @Watch('receitaIntermediaria.valor')
  @Watch('receitaAditamento.valor')
  @Watch('receitaSaldoDevedor.valor')
  ValorTotalContrato(){
    this.item.valor =+ Number(this.receitaSaldoDevedor.valor) + Number(this.receitaAditamento.valor) + Number(this.receitaIntermediaria.valor)  + Number(this.receitaEntrada.valor) 
  } 

  Reset(){
    this.receitaSaldoDevedor = new Receita(1);
    this.receitaEntrada = new Receita(3);
    this.receitaAditamento = new Receita(4);
    this.receitaIntermediaria = new Receita(5);
    this.e13 = 1;
    this.tab = 0;
  }

  ObterGrupos(){
    new EmpreendimentoService().ListarGrupo(this.item.empreendimentoId).then(
      res => {
        this.listaGrupos = res.data
      },
      (err) => {
        TratarErroApi(err);
      }
    );
  } 

  ObterUnidades(){
    new EmpreendimentoService().ListarUnidade(this.item.unidadePrincipal!.grupoId).then(
      (res) => {
        this.listaUnidades = res.data.filter(x => x.situacaoId ==  1 || x.id == this.item.unidadePrincipalId)
      },
      (err) => {
        TratarErroApi(err);
      }
    );
  }

  get anos() {
    var currentYear = new Date().getFullYear();
    var min = currentYear - 10;
    var max = currentYear + 5;

    const anos:any = []; 

    for (let i = min; i <= max; i++) {
      anos.push(i);
    }

    return anos; 
  }

  ObterParametroGatway(item){
    if(item){
      this.item.encargoFinanceiro = item.encargoFinanceiro;
      this.item.tipoGatewayId = item.tipoId;
    }else{
      this.item.encargoFinanceiro = new Encargo();
      this.item.tipoGatewayId = 0;
    }
  }

  meses = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ]; 
   
  mounted() {
    new EmpreendimentoService().Listar(-1, -1, ['nome'], [], undefined, [], '', '', "Id,Nome", "").then(
      res => {
        this.listaEmpreendimentos = res.data.items
      });

    new TipoDocumentoService().ListarTudo().then(
      res => {
        this.listaTiposDocumentos = res.data.items
      });

    new  IntervaloReajusteService().ListarTudo().then(
      res => {
        this.tiposIntervalos = res.data.items
      });

    new TipoAmortizacaoService().ListarTudo().then(
      res => {
        this.tiposAmortizacao = res.data.items.filter(x=>x.id != 3)
      });

    new SituacaoContratoService().ListarTudo().then(
      res => {
        this.listasSituacoes = res.data.items.filter(x => x.id != EnumSitucaoContrato.Aditado && x.id != EnumSitucaoContrato.Cancelado)
      });

    new TipoAditamentoContrato().ListarTudo().then(
      res => {
        this.tiposAditamentos = res.data.items
      });
  
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome, cnpjCpf, nomeCnpjCpf, isCliente, isCorretor', '').then(
      res =>  {
        this.pessoas = res.data.items
      });
 
    new TipoIndiceService().ListarTudo().then(
      res => {
        this.listarTipoIndice = res.data.items
      });
   }
 } 
