
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ContratoService,  EmpreendimentoService,  PessoaService,  TipoIndiceService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { ParametroFinanceiroService, ReceitaService, TipoProdutoContratoService } from "@/core/services/financeiro";
import Pessoa from "@/core/models/cadastros/Pessoa";
import * as jsonpatch from 'fast-json-patch';
import { Contrato, Unidade } from "@/core/models/cadastros";
import { Encargo, ParametroGatway } from "@/core/models/financeiro";
import { EnumSitucaoContrato } from "@/core/models/cadastros/contrato/SituacaoContrato";

@Component
export default class DetalharContrato extends Vue {
  @Prop() public item!: Contrato;
  @Prop() public value!: string;
  

  EnumSitucaoContrato = EnumSitucaoContrato;
 
  service  = new ContratoService();
  receitaService = new ReceitaService();
  observer! : jsonpatch.Observer<Contrato>;
  parametroFinanceiro = new ParametroGatway();
  pessoa = new Pessoa();
  unidade = new Unidade();

  listaGateway =[];
  empresas = [];
  listarTipoIndice = [];
  listaTipoEmpreendimento = [];
  pessoas = [];
  empreendimentos = [];
  listaUnidades = [];
  listaGrupos = [];

  dialogUnidadeInformacao = false;

  tab = null; 
  valid = true;
  dialog = false; 
  dialogCorretor = false;
   
  ObterParametroGatway(item){
   
    if(item){
      this.item.encargoFinanceiro = item.encargoFinanceiro;
      this.item.tipoGatewayId = item.tipoId;
    }else{
      this.item.encargoFinanceiro = new Encargo();
      this.item.tipoGatewayId = 0;
    } 
  }

  ObterParametroPorId(id){
    new ParametroFinanceiroService().ObterParametroEmpresaPorId(id, 'Gatways.Tipo').then(
      res => { 
        this.listaGateway = res.data.gatways;
      }
    )
  }
 
  mesReajuste = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ];

  headerCorretores: any[] = [
    { text: "", value:"actions", sortable: false },
    { text: "Nome", value: "corretorId" },
    { text: "Percentual", value: "percentual" },
  ]; 

 
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
    if (this.dialog){
       this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.ObterParametroPorId(this.item.empresaId);
    }
  }

  Salvar() {
    let pacthModel = jsonpatch.generate(this.observer);
    (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
      res => {
        this.receitaService.AtualizarEncargosContrato(this.item.id, this.item.encargoFinanceiro); 
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");         
        this.$emit("salvou");
        this.Close();
       },
    (err) => { 
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    });
  }

  Close() {
    this.dialog = false;
    this.parametroFinanceiro = new ParametroGatway();
  }
 
  EditarCorretor(item){
    this.pessoa = item;
    this.dialogCorretor = true;
  }

  ObterLote(item){
    new EmpreendimentoService().ObterUnidade(item.id, 'Imovel, Jazigo, Lote, Lote.Infraestrutura').then(
      res => {
        this.dialogUnidadeInformacao = true;
        this.unidade = res.data;  
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
    })
  }

  ObterUnidades(grupo) {
    new EmpreendimentoService().ListarUnidade(grupo.id).then(
      res => {this.listaUnidades = res.data}
    )
  }

  ObterGrupos() {
    new EmpreendimentoService().ListarGrupo(this.item.empreendimentoId).then(
      res => {this.listaGrupos = res.data}
    )
  } 

  listasSituacoes = [
    {id:1, nome: "Em Andamento"},
    {id:2, nome: "Cobrança"},
    {id:3, nome: "Jurídico"},
    {id:4, nome: "Prejuízo"},
    {id:5, nome: "Cancelado"},
    {id:6, nome: "Quitado"},
    {id:7, nome: "Aditado"},
    {id:8, nome: "Com processo"}
  ]; 

 get situacoesFiltradas() {
    const situacoesPermitidas = this.listasSituacoes.filter(
      (situacao) => situacao.id !== EnumSitucaoContrato.Aditado
        && situacao.id !== EnumSitucaoContrato.Cancelado
        && (this.item.situacaoId == EnumSitucaoContrato.Quitado
        || situacao.id !== EnumSitucaoContrato.Quitado));
        
    return situacoesPermitidas;
  }

  mounted() {    
    new EmpreendimentoService().Listar(-1, -1, ['nome'], [], undefined, [], "", '', "Id,Nome", "").then((res) => {
      this.empreendimentos = res.data.items});

    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      res => this.pessoas = res.data.items 
    )
     
    new TipoProdutoContratoService().ListarTudo().then(
      res => this.listaTipoEmpreendimento = res.data.items
    )
  
    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id,nome, nomeFantasia', '').then(
      res => this.empresas = res.data.items
    )

    new TipoIndiceService().ListarTudo().then(
      res => this.listarTipoIndice = res.data.items
    )
  }
}

 
