
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
  import { Chart, Title, Tooltip, Legend, ArcElement, LinearScale, PieController } from 'chart.js';
import { nextTick } from 'vue';
  
  Chart.register(Title, Tooltip, Legend, ArcElement, PieController, LinearScale);
  
  @Component
  export default class GraficoPizzaComponent extends Vue {
    @Prop({ required: true }) chartData!: any;
    @Prop({ required: false }) options!: any;
    @Prop({ required: true }) formatType!: string;
    @Prop({ required: false, default: '100%' }) width!: string;
    @Prop({ required: false, default: '100%' }) height!: string;
    private isChartReady: boolean = false;
  
    private chartInstance: any = null;

    mounted() {
    nextTick(() => {
      this.isChartReady = true;  // Now the canvas is ready
      this.renderChart();
    });
    }

    beforeDestroy() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
    }

    @Watch('chartData', { immediate: true, deep: true })
    @Watch('options', { immediate: true, deep: true })
    onDataChange() {
    if (this.isChartReady) {
      this.renderChart();
    }
  }

  private formatValue(value: any): string {
  // Verifique se o valor é um número
  const numValue = Array.isArray(value) ? value[0] : value; // Caso o valor seja um array, pegue o primeiro item
  if (typeof numValue === 'number') {
    if (this.formatType === 'currency') {
      return 'R$ ' + numValue.toFixed(2); // Formato moeda
    } else {
      return numValue.toString(); // Formato número inteiro
    }
  }
  return value.toString(); // Caso não seja um número, retorna como string
}

    private renderChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      const canvas = this.$refs.pieChart as HTMLCanvasElement | undefined;

      if (canvas && canvas.getContext) {
      const ctx = canvas.getContext('2d');
      this.chartInstance = new Chart(ctx, {
        type: 'pie',
        data: this.chartData,
        
        options: {...this.options,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
            legend: {
                position: 'left',
                labels: {
                usePointStyle: true,
                padding: 11,
                font: {
                    size: 14
                  }
                },
            },
            tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                  let dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
                  let label = tooltipItem.chart.data.labels[tooltipItem.dataIndex] || '';  // Pega o rótulo correspondente ao dado
                  if (label) {
                    label += ': ';
                  }
                  const value = Array.isArray(tooltipItem.raw) ? tooltipItem.raw[0] : tooltipItem.raw;
                  label += this.formatValue(value);
                  return label;
                }
                        }
                      }
                    },
            elements: {
              arc: {
                borderWidth: 1,
              }
          },
        tooltips: {
            enabled: false
         }
        }
      });
      }
    }
}
  