
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Orcamento,OrcamentoFornecedor,OrcamentoItem,OrcamentoItemFornecedor} from "../../../core/models/compras";
import { Produto} from "../../../core/models/almoxarifado";
import { EmpresaService } from "../../../core/services/compras/EmpresaService";
import { TipoProdutoService,ProdutoService,GrupoProdutoService} from "../../../core/services/almoxarifado";
import { FormaPagamentoService } from "../../../core/services/financeiro";
import Pessoa from "@/core/models/cadastros/Pessoa";
import { PessoaService } from "@/core/services/cadastros";
import * as jsonpatch from "fast-json-patch"; 
import { OrcamentoService } from "@/core/services/compras/OrcamentoService";
   
@Component
export default class CadastroOrcamento extends Vue {
  @Prop() public item!: Orcamento;
  @Prop() public value!: string;  

  service = new OrcamentoService();

  fornecedorOrcamento = new OrcamentoFornecedor();
  itemOrcamento = new OrcamentoItem();
  itemFornecedor = new OrcamentoItemFornecedor();
   
  Pessoa = new Pessoa();
  Produto = new Produto(); 

  listaPessoa = [];
  formaPagamentos = [];
  classificacoes = [];
  empresas = [];
  grupos = [];
  produtosx:any = [];
  expanded = []; 
  gruposProdutos = [];

  overlay: boolean = false;
  observer!: jsonpatch.Observer<Orcamento>;

  dialogAdicionarFornecedor: boolean = false;
  dialogCadastroProduto: boolean = false;
  dialogCadastroPessoa: boolean = false;
  singleExpand: boolean = false;
  expand: boolean = false;
  dialog: boolean = false;
  valid: boolean = true;
  
  tabActive = 0; 
  fornecedorIndex = -1;
  orcamentoIndex = -1;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    formCadastroOrcamento: HTMLFormElement;
    formFornecedorOrcamento: HTMLFormElement;
    formProdutos: HTMLFormElement;
    formFornecedor: HTMLFormElement;
  };

  headersFornecedor: any[] = [
    { text: " ", value: "actions", sortable: false },
    { text: "Fornecedor", value: "fornecedorId" },
    { text: "Valor unitário", value: "valorUnitario" },
    { text: "Valor total", value: "valorTotal" },
  ];
  
  headersFornecedoresCotacao: any[] = [
    { text: " ", value: "actions", sortable: false },
    { text: "Fornecedor", value: "fornecedor.nome", sortable: false },
    { text: "Forma pag.", value: "formaPagamento.nome", sortable: false },
    { text: "Observação", value: "observacao", sortable: false },
    { text: "Prazo de entrega em dias", value: "prazoEntregaDias", sortable: false },
    { text: "Validade em dias", value: "validadeDias", sortable: false },
    { text: "Frete", value: "frete", sortable: false },
  ];

  headersFornecedores: any[] = [
    { text: "Fornecedor", value: "fornecedorId", sortable: false },
    { text: "Valor", value: "valor", sortable: false },
  ];

  headersProdutos: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Produto", value: "produtoId", sortable: false },
    { text: "Quantidade", value: "quantidade", sortable: false },
  ];

  @Watch("item")
  Item(val) {
    if (this.$refs.formCadastroOrcamento) {
      this.$refs.formCadastroOrcamento.resetValidation();
    }
  }

  @Watch("fornecedorOrcamento")
  FornecedorOrcamento() {
    if (this.$refs.formFornecedorOrcamento) {
      this.$refs.formFornecedorOrcamento.resetValidation();
    }
  } 

  @Watch("itemOrcamento")
  ItemOrcamento() {
    if (this.$refs.formProdutos) {
      this.$refs.formProdutos.resetValidation();
    }
  }

  @Watch("itemFornecedor")
  ItemFornecedor() {
    if (this.$refs.formFornecedor) {
      this.$refs.formFornecedor.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog) {
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.produtosx = this.item.itens;
    } 
  }

  @Watch("itemFornecedor.valorUnitario")
  ValorTotal(){     
    const itemFiltrado = this.item.itens.find(x => x.produtoId === this.itemOrcamento.produtoId && x.quantidade);
    if (itemFiltrado) {
      const valorTotal = this.itemFornecedor.valorUnitario * itemFiltrado.quantidade;
      this.itemFornecedor.valorTotal = Number(valorTotal.toFixed(2));
    } else {
      this.itemFornecedor.valorTotal = 0;
    }
  }

  ObterProdutos() {
    new ProdutoService().ListarTudoFiltro(`grupoId eq ${this.itemOrcamento.grupoId}`).then(
      (res) => {
        this.gruposProdutos = res.data.items;
      }
    );
  }

  estoqueMinimo:number = 0;
  estoqueCritico:number = 0;
  ultimaCompraPreco:number = 0;
  saldo:number = 0;

  ObterProduto(id) {
    new ProdutoService().ObterPorId(id, 'AlmoxarifadoItens').then(res => {
      this.saldo = res.data.saldo;
      this.ultimaCompraPreco = res.data.ultimaCompraPreco;
      this.estoqueMinimo = res.data.estoqueMinimo;
      this.estoqueCritico = res.data.estoqueCritico;
    })
  }

  NovaPessoa() {
    this.Pessoa = new Pessoa();
    this.dialogCadastroPessoa = true;
  }

  AtualizarPessoa() {
    new PessoaService().ListarTudo().then((res) => {
      this.listaPessoa = res.data.items;
    })
  }

  abrirDialogFornecedor(item: OrcamentoItem) {
    this.itemOrcamento = item;
    this.dialogAdicionarFornecedor = true;
  }

  fecharDialogFornecedor() {
    this.itemOrcamento = new OrcamentoItem();
    this.dialogAdicionarFornecedor = false;
  }

  Salvar() { 
    this.$refs.formCadastroOrcamento.validate();

    if (!this.Validacoes()) { 
      this.overlay = !this.overlay;
      this.item.fornecedores.forEach(x=> x.valor = undefined);
      let modelo = this.item;

      let pacthModel = jsonpatch.generate(this.observer);
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(modelo)).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },

        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
    }
  }

  Close() {
    this.expanded = [];
    this.overlay = false;
    this.dialog = false;
    this.tabActive = 0;
  }

  Validacoes(){
    if(!this.item.empresaId || !this.item.classificacaoId ){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tabActive = 0;
      return true; 
    }

    if(this.item.fornecedores.length == 0){
      this.$swal("Aviso", "É necessário adicionar Fornecedores para a cotação de preços.", "warning");
      this.tabActive = 0;
      return true; 
    }

    if(this.item.itens.length ==0){
      this.$swal("Aviso", "É necessário adicionar Produtos para a cotação de preços.", "warning");
      this.tabActive = 1;
      return true; 
    }
  }
  
  AdicionarOrcamentoFornecedor(){
     if (this.$refs.formFornecedorOrcamento.validate()) {
      if (this.fornecedorIndex > -1) {
        Object.assign(this.item.fornecedores[this.fornecedorIndex],this.fornecedorOrcamento);
      } else {
        this.item.fornecedores.push(this.fornecedorOrcamento);
      }
      this.fornecedorOrcamento = new OrcamentoFornecedor();
      this.fornecedorIndex = -1;
     }
  }

  Editar(item) {
    this.fornecedorIndex = this.item.fornecedores.indexOf(item);
    this.fornecedorOrcamento = Object.assign({}, item);
  }
 
  Excluir(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.item.fornecedores.indexOf(item);
        this.item.fornecedores.splice(index, 1);
        this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      }
    });
  }

  /* Adicionar, Editar e Excluir Produtos e Serviços */
  AdicionarProdutos() {
    if (this.$refs.formProdutos.validate()) {
      if (this.item.itens.find((x) => x.produtoId == this.itemOrcamento.produtoId)) {
        this.$swal("Alerta!","Esse produto já foi incluído na lista!","warning");
        return;
      }
      if (this.item.fornecedores.length == 0) {
        this.$swal("Alerta!","É necessário incluir pelo menos um Fornecedor!","warning");
        return;
      }
      if (this.orcamentoIndex == -1) {
        this.item.itens.push(this.itemOrcamento);
      }
      if (this.fornecedorIndex == -1) {
        for (let i = 0; i < this.item.fornecedores.length; i++) {
          let fornecedor = new OrcamentoItemFornecedor();

          fornecedor.fornecedorId = this.item.fornecedores[i].fornecedorId;
          fornecedor.valorUnitario = Number(this.item.fornecedores[i].valor);
          fornecedor.valorTotal = this.itemOrcamento.quantidade * Number(this.item.fornecedores[i].valor);
          
          this.itemOrcamento.itemFornecedores.push(fornecedor);
           this.item.fornecedores[i].valor = undefined
        }
      }

      this.item.fornecedores.forEach(fornecedor => {
        this.item.itens.forEach(item => {
          item.itemFornecedores.filter(x => x.fornecedorId == fornecedor.id).reduce((sum, x) => fornecedor.total += x.valorTotal, 0)
        });
      });

      this.itemOrcamento.quantidade = Number(this.itemOrcamento.quantidade);
      this.itemOrcamento = new OrcamentoItem();
      this.orcamentoIndex = -1;
    }
  }

  EditarProdutos(item) {
    this.orcamentoIndex = this.item.itens.indexOf(item);
    this.itemOrcamento = Object.assign({}, item);;
  }

  ExcluirProduto(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
         item.excluido = true;
         const index = this.item.itens.indexOf(item);
         delete this.item.itens[index]
         this.dialog = false;
         this.dialog = true;
         return true;
        }else{
        const index = this.item.itens.indexOf(item);
        this.item.itens.splice(index, 1);
        return this.$swal("Aviso", "Item excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  } 

  NovoProduto() {
    this.Produto = new Produto();
    this.dialogCadastroProduto = true;
  }
  
  /* Adicionar, Editar e Excluir Fornecedor */
  AdicionarFornecedor() {
    if (this.$refs.formFornecedor.validate()) {
      if (this.fornecedorIndex > -1) {
        Object.assign(this.itemOrcamento.itemFornecedores[this.fornecedorIndex], this.itemFornecedor);
      } else {
       this.itemOrcamento.itemFornecedores.push(this.itemFornecedor);
      }
      this.itemFornecedor = new OrcamentoItemFornecedor();
      this.fornecedorIndex = -1;
    }
  }

  EditarFornecedor(item) {
    this.fornecedorIndex = this.itemOrcamento.itemFornecedores.indexOf(item);
    this.itemFornecedor = Object.assign({}, item);;
  }

  ExcluirFornecedor(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.itemOrcamento.itemFornecedores.indexOf(item);
        this.itemOrcamento.itemFornecedores.splice(index, 1);
        this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      }
    });
  }

  itemExcluido(item:any){
    if(item.excluido)
    return 'itemExcluido'
    else
    return ''
  }

 /* AtualizarProduto() {
    new ProdutoService().Listar(-1, -1, ['id'],[],'',[], '', '' , 'Id,nome,idNome', '').then((res) => {
      this.gruposProdutos = res.data.items;
    });
  }*/

  produtos = [];

  mounted() {
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(res => {
      this.listaPessoa = res.data.items;
    })

   

     new ProdutoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
    (res) => {
      this.produtos = res.data.items;
    })

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,nomeFantasia', '').then(
    (res) => {
      this.empresas = res.data.items;
    });

    new TipoProdutoService().ListarTudo().then(
    (res) => {
      this.classificacoes = res.data.items;
    });

    new GrupoProdutoService().ListarTudo().then(
    (res) => {
      this.grupos = res.data.items;
    });

    new FormaPagamentoService().ListarTudo().then(
    (res) => {
      this.formaPagamentos = res.data.items;
    });
  }
}
