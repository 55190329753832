
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {ContaCorrenteService,BancoService} from "@/core/services/financeiro";
import { ContaCorrente, Banco } from "@/core/models/financeiro";
import { EmpresaService } from "../../../core/services/compras/EmpresaService";
import { Empresa } from "@/core/models/cadastros";
import * as jsonpatch from 'fast-json-patch';
import { TratarErroApi } from "@/assets/scripts/helper";

@Component
export default class CadastroContaCorrente extends Vue {
  @Prop() public item!: ContaCorrente;
  @Prop() public value!: string;

  service = new ContaCorrenteService();
  listaBanco: Banco[] = [];
  listaEmpresa: Empresa[] = [];

  valid = true;
  dialog = false;
  arquivo = null;  
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
   
  observer! : jsonpatch.Observer<ContaCorrente>; 
 
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }
 
  mounted() {
    let bancoService = new BancoService();
    bancoService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'id, nome', '').then(
      (res) => {this.listaBanco = res.data.items},
    );

    let empresaService = new EmpresaService();
    empresaService.Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(
      (res) => {this.listaEmpresa = res.data.items;},
    );
  }

  Salvar() { 
    if (this.$refs.form.validate()) { 
      let pacthModel = jsonpatch.generate(this.observer);
    
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
      (err) => { TratarErroApi(err) });
    }
  }

  CarregarDadosArquivo(){
    if(!this.arquivo){
      return;
    }

    this.service.UploadCertificadoDigital(this.item.id, this.arquivo).then(
      (res) => {
        this.$emit("salvou")
        },
      (err) => { TratarErroApi(err) })
  }

  Close() {
    this.dialog = false; 
    this.arquivo = null
  }
}
