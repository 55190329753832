import Vue from 'vue'
import MasterPage from './shared/MasterPage.vue';
import RelatorioPage from './shared/RelatorioPage.vue';
import CadastroUsuario from './administracao/usuario/Cadastro.vue';
import MeusDados from './administracao/usuario/MeusDados.vue';
import CadastroAssinante from './administracao/assinante/Cadastro.vue' ;
import DadosAssinante from './administracao/assinante/DadosAssinante.vue';
import CadastroIndice from '@/components/vendas/indice/Cadastro.vue'; 
import CadastroEmpresa from './negocios/empresa/Cadastro.vue';
import CadastroCartorio from '@/components/negocios/cartorio/Cadastro.vue';
import CadastroPessoa from '@/components/negocios/pessoa/Cadastro.vue';
import DetalharPessoa from '@/components/vendas/contrato/visualizar/VisualizarPessoa.vue';
import NovoEmpreendimento from '@/components/negocios/empreendimento/Cadastro.vue';
import ListaMultiLotes from '@/components/negocios/empreendimento/ListaMultiLotes.vue';
import Unidade from '@/components/negocios/empreendimento/VisualizarUnidade.vue';
import LadosAdicionais from '@/components/negocios/empreendimento/LadosAdicionais.vue';
import GerarGrupos from '@/components/negocios/empreendimento/GerarGrupos.vue';
import TipoProcessoJudicial from '@/components/vendas/tipoprocesso/Cadastro.vue';
import ObservacaoInput from '@/components/shared/AdicionarObservacao.vue'; 
import EnderecoInput from '@/components/shared/Endereco.vue'; 
import MoneyInput from '@/components/shared/MoneyInput.vue';
import AdicionarLotes from '@/components/shared/AdicionarLote.vue';
import VCardDocumento from '@/components/shared/AdicionarDocumento.vue';
import VcardGraficoCircular from '@/components/shared/VcardGraficoCircular.vue';
import VerTodosVendas from '@/components/shared/VerTodosVendas.vue';
import LinksDashboard from '@/components/shared/LinksDashboard.vue';
import VCardDocumentoNovo from '@/components/shared/VCardDocumentoNovo.vue';
import ValoresAdicionaisReceita from '@/components/shared/ValoresAdicionais.vue';
import VCardHistorico from '@/components/shared/Historico.vue';
import VCardContato from '@/components/shared/AdicionarContato.vue';
import Mapear from '@/components/negocios/mapainterativo/Mapear.vue';
import VisualizarMapaInterativo from '@/components/negocios/mapainterativo/Visualizar.vue'; 
import VDataPicker from '@/components/shared/VDataPicker.vue';

/* Gráficos */
import GraficoBarraComponent from '@/components/graficos/GraficoBarraComponent.vue';
import GraficoPizzaComponent from './graficos/GraficoPizzaComponent.vue';
/* Fim Gráficos */

/* REGUA */
import CadastroRegua from './vendas/regua/Cadastro.vue';
import CadastroBanco from './financeiro/banco/Cadastro.vue';
import CadastroContaCorrente from './financeiro/contacorrente/Cadastro.vue';
import CadastroTipoDocumento from './financeiro/tipodocumento/Cadastro.vue';
import CadastroRetornoBancario from './financeiro/retornobancario/Cadastro.vue';
import CadastroRemessa from './financeiro/remessa/Cadastro.vue';
import CadastroFormaPagamento from './financeiro/formapagamento/Cadastro.vue';
import CadastroPlanoPagamento from './vendas/planopagamento/Cadastro.vue';
import CadastroDespesa from './financeiro/despesa/Cadastro.vue';
import CadastroReceita from './financeiro/receita/Cadastro.vue';
import ParcelaBaixa from './financeiro/despesa/ParcelaBaixa.vue';
import ParcelaBaixaReceita from './financeiro/receita/ParcelaBaixa.vue';
import DetalharDespesa from './financeiro/despesa/DetalharDespesa.vue' 
import ParcelaDetalheReceita from './financeiro/receita/ParcelaDetalhe.vue';
import EditarParcela from './financeiro/receita/EditarParcela.vue'; 
import EditarParcelaDespesa from './financeiro/despesa/EditarParcela.vue';
import GerenciadorReceita from '@/components/financeiro/receita/GerenciadorReceita.vue';

import GerenciadorReceitaPrice from '@/components/financeiro/receita/GerenciadorReceitaPrice.vue';
import ValoresAdicionais from '@/components/financeiro/receita/ValoresAdicionais.vue';
import CadastroParametroContrato from './vendas/parametro/Cadastro.vue';
import CadastroTipoServicoReceita from './financeiro/tiposervicoreceita/Cadastro.vue';
import Financeiro from './compra/pedido/Despesa.vue';
import Requisicao from './compra/pedido/Requisicao.vue';
import ReceberPedido from './compra/pedido/ReceberPedido.vue';
import Detalhar from './compra/pedido/Detalhar.vue';
import XmlPedido from './compra/pedido/Xml.vue';
import CadastroOrcamento from './compra/orcamento/Cadastro.vue';
import DetalharOrcamento from './compra/orcamento/Aprovar.vue';
import CadastroParametro from './compra/parametro/Cadastro.vue'; 
import CadastroAlmoxarifado from './almoxarifado/almoxarifado/Cadastro.vue';
import CadastroUnidadeMedida from './almoxarifado/unidademedida/Cadastro.vue';
import CadastroGrupoProduto from './almoxarifado/grupoproduto/Cadastro.vue';
import CadastroProduto from './almoxarifado/produto/Cadastro.vue';
import CadastroMovimentacao from './almoxarifado/movimentacao/Cadastro.vue';
import TransferenciaMovimentacao from './almoxarifado/movimentacao/Transferencia.vue';
import CadastroRequisicao from './almoxarifado/requisicao/Cadastro.vue';
import AtenderRequisicao from './almoxarifado/requisicao/Atender.vue';
import CadastroInventario from './almoxarifado/inventario/Cadastro.vue';
import Atender from './almoxarifado/inventario/Atender.vue';
import AgruparAntecipar from '@/components/vendas/contrato/acoes/Agrupar.vue';
import QuitadorContrato from '@/components/vendas/contrato/acoes/Quitar.vue'; 
import Simular from '@/components/vendas/contrato/acoes/SimularAmortizacao.vue';
import ConfigurarReceita from '@/components/vendas/contrato/acoes/Configurar.vue';
import AjusteAmortizacao from '@/components/vendas/contrato/parcela/ReajustarValor.vue';
import EditarParcelaManual from '@/components/vendas/contrato/parcela/Editar.vue';
import AtualizarDataSequencia from '@/components/vendas/contrato/parcela/ReajustarData.vue';
import AnteciparContrato from '@/components/vendas/contrato/parcela/Antecipar.vue';
import BaixasRetroativa from '@/components/vendas/contrato/parcela/BaixaRetroativa.vue';
import AgruparParcela from '@/components/vendas/contrato/parcela/Agrupar.vue';
import EmpreendimentoContrato from '@/components/vendas/contrato/visualizar/VisualizarEmpreendimento.vue'; 
import DespesaContratual from '@/components/vendas/contrato/acoes/ReceitaServico.vue';
import EncargosDescontos from '@/components/vendas/contrato/acoes/EncargoFinanceiro.vue';
import HistoricoContrato from '@/components/vendas/contrato/visualizar/VisualizarHistorico.vue';
import NovoCadastroContrato from '@/components/vendas/contrato/Cadastro.vue';
import AtalhoFiltro from '@/components/vendas/contrato/Atalho.vue';
import Aditar from '@/components/vendas/contrato/Aditar.vue';
import DetalharContrato from '@/components/vendas/contrato/visualizar/VisualizarContrato.vue';
import GerenciadorContrato from '@/components/vendas/contrato/Gerenciar.vue';
import VisualizarContrato from './vendas/contrato/Visualizar.vue';
import VisualizarMultiplosLotes from './vendas/contrato/Visualizar.vue';
import ModeloContrato from '@/components/vendas/modelodigital/Cadastro.vue';
import VcardContratodigital from '@/components/vendas/contratodigital/ListaContratoDigital.vue';
import ContratoDigital from '@/components/vendas/contratodigital/Cadastro.vue';
import VisualizarContratoDigital from '@/components/vendas/contratodigital/Visualizar.vue';
import CadastroSignatario from '@/components/vendas/signatario/Cadastro.vue';
import CadastroTipoContrato from '@/components/vendas/tipocontrato/Cadastro.vue';
import ExecutarMedicao from '@/components/prestacaoservico/medicao/Executar.vue';
import LiberarMedicao from '@/components/prestacaoservico/medicao/Liberar.vue';
import RegistrarMedicao from '@/components/prestacaoservico/medicao/Registrar.vue'; 
//import CadastroOrcamentos from './obras/orcamento/Cadastro.vue';
import NovoFornecedor from '@/components/prestacaoservico/contrato/Cadastro.vue';
import DetalharContratoFornecedor from '@/components/prestacaoservico/medicao/Detalhar.vue';

import InfoPlanoPagamento from '@/components/negocios/empreendimento/VisualizarPlano.vue';
Vue.component('plano-pagamento-info',  InfoPlanoPagamento);

/* GESTÃO DE AGUAS */
import CadastroConsumo from './agua/consumo/Cadastro.vue';
import CadastroHidrometro from './agua/hidrometro/Cadastro.vue';
import CadastroQualidade from './agua/qualidadeagua/Cadastro.vue';
import CadastroMarcacao from './agua/marcacao/Cadastro.vue';

Vue.component('cadastro-aguaconsumo', CadastroConsumo);
Vue.component('cadastro-aguahidrometro', CadastroHidrometro);
Vue.component('cadastro-aguaqualidade', CadastroQualidade);
Vue.component('cadastro-aguamarcacao', CadastroMarcacao);

/* ORCAMENTO DE OBRAS */
import CadastroOrcamentoObras from './obras/orcamento/Cadastro.vue';
import CadastroTipoComposicao from './obras/tipocomposicao/Cadastro.vue';
import CadastroClasseComposicao from './obras/classecomposicao/Cadastro.vue';
import CadastroComposicao from './obras/composicao/Cadastro.vue';
import CadastroInsumo from './obras/insumo/Cadastro.vue';
import InsumoEstado from './obras/composicao/VCardValoresUF.vue'; 
 
Vue.component('cadastro-orcamentoobras', CadastroOrcamentoObras);
Vue.component('cadastro-tipocomposicao', CadastroTipoComposicao);
Vue.component('cadastro-classecomposicao', CadastroClasseComposicao);
Vue.component('cadastro-composicao', CadastroComposicao);
Vue.component('cadastro-insumo', CadastroInsumo);
Vue.component('insumo-estado', InsumoEstado);
  
Vue.component('atalho-filtro', AtalhoFiltro);
Vue.component('detalhes-contratoEmpreiteira', DetalharContratoFornecedor);
Vue.component('novo-fornecedor', NovoFornecedor); 
Vue.component('liberar-medicao', LiberarMedicao);
Vue.component('registrar-medicao', RegistrarMedicao); 
Vue.component('execucoes', ExecutarMedicao);
Vue.component('visualizar-contrato', VisualizarContrato);
Vue.component('visualizar-multiplosLotes', VisualizarMultiplosLotes);
Vue.component('dados-assinante', DadosAssinante);
Vue.component('cadastro-assinante', CadastroAssinante);
Vue.component('cadastro-usuario', CadastroUsuario);
Vue.component('meus-dados', MeusDados)
Vue.component('cadastrar-regua', CadastroRegua);
Vue.component('novo-cadastro-contrato', NovoCadastroContrato);  
Vue.component('detalhar-contrato', DetalharContrato);
Vue.component('encargos-descontos', EncargosDescontos);
Vue.component('empreendimento-contrato', EmpreendimentoContrato);
Vue.component('cadastro-cartorio', CadastroCartorio);
Vue.component('master-page', MasterPage);
Vue.component('relatorio-page', RelatorioPage);
Vue.component('cadastro-banco', CadastroBanco);
Vue.component('cadastro-tipoprocessojudicial', TipoProcessoJudicial);
Vue.component('novo-empreendimento', NovoEmpreendimento);
Vue.component('lista-multilotes', ListaMultiLotes);
Vue.component('visualizar-unidade', Unidade);
Vue.component('lados-adicionais', LadosAdicionais);
Vue.component('gerar-grupos', GerarGrupos);

Vue.component('cadastro-contacorrente', CadastroContaCorrente);
Vue.component('cadastro-tipodocumento', CadastroTipoDocumento);
Vue.component('cadastro-retornobancario', CadastroRetornoBancario);
Vue.component('cadastro-remessa', CadastroRemessa);
Vue.component('cadastro-formapagamento', CadastroFormaPagamento);
Vue.component('cadastro-planopagamento', CadastroPlanoPagamento);
Vue.component('cadastro-despesa', CadastroDespesa);   
Vue.component('cadastro-receita', CadastroReceita); 
Vue.component('editar-parcela', EditarParcela); 
Vue.component('editar-parcelaDespesa', EditarParcelaDespesa); 
Vue.component('parcelabaixa-despesa', ParcelaBaixa); 
Vue.component('parcelabaixa-receita', ParcelaBaixaReceita);
Vue.component('detalhar-despesa', DetalharDespesa);
Vue.component('parceladetalhe-receita', ParcelaDetalheReceita);
Vue.component('cadastro-tiposervicoreceita', CadastroTipoServicoReceita)
Vue.component('cadastro-almoxarifado', CadastroAlmoxarifado);
Vue.component('cadastro-undiademedida', CadastroUnidadeMedida);
Vue.component('cadastro-grupoproduto', CadastroGrupoProduto);
Vue.component('cadastro-produto', CadastroProduto);
Vue.component('cadastro-movimentacao', CadastroMovimentacao);
Vue.component('transferencia-movimentacao', TransferenciaMovimentacao);
Vue.component('cadastro-requisicao', CadastroRequisicao);
Vue.component('atender-requisicao', AtenderRequisicao);
Vue.component('cadastro-inventario', CadastroInventario);
Vue.component('atender-inventario', Atender);
Vue.component('financeiro-pedido', Financeiro);
Vue.component('receber-pedido', ReceberPedido);
Vue.component('xml-pedido', XmlPedido);
Vue.component('requisicao-pedido', Requisicao); 
Vue.component('detalhar-pedido', Detalhar);
Vue.component('cadastro-orcamento', CadastroOrcamento);
Vue.component('detalhar-orcamento', DetalharOrcamento);
Vue.component('cadastro-parametro', CadastroParametro);
Vue.component('cadastro-parametro-contrato', CadastroParametroContrato);
Vue.component('cadastro-empresa', CadastroEmpresa);
Vue.component('cadastro-indice', CadastroIndice);
Vue.component('cadastro-pessoa', CadastroPessoa);
Vue.component('detalhar-pessoa', DetalharPessoa)
Vue.component('gerenciador-receitaprice', GerenciadorReceitaPrice);
Vue.component('gerenciador-receita', GerenciadorReceita);
Vue.component('valores-adicionais', ValoresAdicionais);
Vue.component('gerenciador-contrato', GerenciadorContrato);
Vue.component('vcard-endereco', EnderecoInput);
Vue.component('money-input', MoneyInput); 
Vue.component('multi-lotes', AdicionarLotes);
Vue.component('mapear-mapa', Mapear);
Vue.component('visualizar-mapa-interativo', VisualizarMapaInterativo);
Vue.component('vcard-observacao', ObservacaoInput); 
Vue.component('vcard-historico', VCardHistorico);
Vue.component('visualizar-historico', HistoricoContrato);
Vue.component('vcard-contato', VCardContato);
Vue.component('vcard-documento', VCardDocumento);
Vue.component('VcardGraficoCircular', VcardGraficoCircular);
Vue.component('VerTodosVendas', VerTodosVendas);
Vue.component('LinksDashboard', LinksDashboard);
Vue.component('v-card-documento-novo', VCardDocumentoNovo);
Vue.component('valores-adicionaisreceita', ValoresAdicionaisReceita);
Vue.component('modelo-digital', ModeloContrato);   
Vue.component('contrato-digital', ContratoDigital);   
Vue.component('vcard-contratodigital', VcardContratodigital);  
Vue.component('visualizar-contrato-digital', VisualizarContratoDigital);  
Vue.component('cadastro-signatario', CadastroSignatario);  
Vue.component('cadastro-tipocontrato', CadastroTipoContrato);  
Vue.component('aditar-contrato', Aditar);
Vue.component('quitador-contrato', QuitadorContrato);
Vue.component('antecipar-contrato', AnteciparContrato); 
Vue.component('baixas-retroativa', BaixasRetroativa);  
Vue.component('despesa-contratual', DespesaContratual)
Vue.component('agrupar-parcela', AgruparParcela)
Vue.component('atualizar-dataSequencia', AtualizarDataSequencia);
Vue.component('editar-parcelaManual', EditarParcelaManual);  
Vue.component('ajuste-amortizacao', AjusteAmortizacao);  
Vue.component('agrupar-antecipar', AgruparAntecipar);
Vue.component('configurar-receita', ConfigurarReceita);  
Vue.component('simular-parcela', Simular);
Vue.component('VDataPicker', VDataPicker);
Vue.component('GraficoBarraComponent', GraficoBarraComponent);
Vue.component('GraficoPizzaComponent', GraficoPizzaComponent);

