
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DespesaService, TipoDocumentoService,SituacaoDespesaParcelaService,CentroCustoService,ContaGerencialService} from "@/core/services/financeiro";
import { Despesa,DespesaParcela, DespesaClassificacao} from "@/core/models/financeiro";
import { TipoDespesaService } from "../../../core/services/financeiro/TipoDespesaService";
import { EmpresaService } from "../../../core/services/compras/EmpresaService";
import { PessoaService, EmpreendimentoService} from "../../../core/services/cadastros";
import Pessoa from "../../../core/models/cadastros/Pessoa";
import * as jsonpatch from 'fast-json-patch';
import { SituacaoColor, TratarErroApi } from '@/assets/scripts/helper';
import { Pedido } from "@/core/models/compras";
import { boleto } from 'boleto-brasileiro-validator';
   
@Component
export default class CadastroDespesa extends Vue {
  @Prop() public item!: Despesa;
  @Prop() public value!: string;
  @Prop() public pedidos!: [];

  service = new DespesaService();
  classificacao = new DespesaClassificacao();
  parcela = new DespesaParcela();
  Pessoa = new Pessoa(); 

  validaBoleto = [(v) => v.length == 0 || boleto(v) || "Código do boleto inválido!"];
   
  situacoes = []; 
  empresas = [];
  tipoDocumentos = [];
  tipoDespesas = [];  
  empreendimentos = [];
  centroCustosOrdenada = [];
  contaGerenciaisOrdenada = [];
  pessoas = [];
  parcelas:any = [];
  
  parcelaIndex = -1;
  classificacaoIndex = -1;
   
  dialogCadastroPessoa = false;
  tabActive = 0;
  dialogParcela = false;
  loading = false;
  valid = true;
  dialog = false;
  overlay = false;

  numeroParcelas = 0;
  valorInicialParcelas = 0;
 
  options: any = {
    itemsPerPage: 15,
  };

  $refs!: {
    formDespesa: HTMLFormElement;
    formParcela: HTMLFormElement;
    formClassificacao: HTMLFormElement
  }; 

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  headersParcela = [
    { text: "", value: "actions", sortable: false },
    { text: "Parcela", value: "parcela" },
    { text: "Valor", value: "valor" },
    { text: "Valor Pago", value: "valorPago" },
    { text: "Linha Digitável", value: "linhaDigitavelBoleto" },
    { text: "Vencimento", value: "dataVencimento" },
    { text: "Situação", value: "situacaoId" },
  ];

  headersClassificacao = [
    { text: "", value: "actions", sortable: false },
    { text: "Centro Custo", value: "centroCustoId" },
    { text: "Conta Gerencial", value: "contaGerencialId" },
    { text: "Valor", value: "valor" },
    { text: "Percentual", value: "percentual" },
  ];
 
  @Watch("item")
  Item() {
    if (this.$refs.formDespesa) {
      this.$refs.formDespesa.resetValidation();
    }
  }

  @Watch("parcela")
  Parcela() {
    if (this.$refs.formParcela) {
      this.$refs.formParcela.resetValidation();
    }
  }

  @Watch("classificacao")
  Classificacao() {
    if (this.$refs.formClassificacao) {
      this.$refs.formClassificacao.resetValidation();
    }
  }

  observer! : jsonpatch.Observer<Despesa>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 
    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      if(this.item.id>0){
         this.ObterEmpreendimentosPorEmpresa();
        }
      } 
    };

  @Watch("item", {deep:true})
  ObservadorItem(){
    this.observer = jsonpatch.observe(this.item);
  };
  
  Validacoes(){
    if(!this.item.numeroDocumento || !this.item.dataEmissao || !this.item.tipoId || !this.item.tipoDocumentoId || !this.item.valor || !this.item.fornecedorId || !this.item.empresaId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tabActive = 0;
      return true;
    }
    if(this.item.parcelas.length == 0){
      this.$swal("Aviso", "É necessário adicionar as parcelas.", "warning");
      this.tabActive = 1;
      return true;
    }
    if(this.item.classificacoes.length == 0){
      this.$swal("Aviso", "É necessário adicionar as classificações.", "warning");
      this.tabActive = 2;
      return true;
    }
  }   

  Salvar() {  
    this.$refs.formDespesa.validate();
    if (!this.Validacoes()) {
      const model = this.CriarModelDespesaPedido();

      this.overlay = !this.overlay;
      let pacthModel = jsonpatch.generate(this.observer);
      
      (this.pedidos.length > 0 ? this.service.GerarDespesaPedido(model) : (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item))).then(
        (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },  
      (err) => TratarErroApi(err)
      ).finally(() => this.overlay = false);
    }
  }

  CriarModelDespesaPedido(){
    let model = new ModelDespesaPedido();

      if(this.pedidos.length > 0){
        this.pedidos.forEach((x:any) => {x.orcamento = undefined,x.tipo = undefined,x.itens = undefined,x.fornecedor = undefined});
        model.despesa = this.item;
        model.pedidos = this.pedidos;
      }

    return model;
  }
  
  Close() { 
    this.Reset();
    this.dialog = false;
  }

  NovaPessoa(){
    this.Pessoa = new Pessoa();
    this.dialogCadastroPessoa = true;
  }
      
  GerarParcelas(){     
    this.parcelas = [...this.item!.parcelas];

    if(this.item.valor <= 0){
      this.$swal("Aviso", "O campo valor não pode ser menor que 0.", "warning");
      this.tabActive = 0;
      return;
    }
    
    if (this.item.parcelas.some(parcela => parcela.situacaoId !== 1)) 
      return this.$swal("Aviso", "Não é possível editar uma parcela paga ou cancelada.", "warning");
  
    this.item.parcelas = []; 
 
    for(let i = 0; i < this.numeroParcelas; i++){
      let parcela = new DespesaParcela();

      let dataInicial = new Date(`${this.parcela.dataVencimento} 00:00:00`);
      let dataCalculada = new Date(dataInicial.setMonth(dataInicial.getMonth() + i));

      parcela.parcela = i + 1;
      parcela.valor = this.valorInicialParcelas;
      parcela.situacaoId = 1;
      parcela.dataVencimento = dataCalculada.toISOString().split('T')[0];
      parcela.linhaDigitavelBoleto = this.parcela.linhaDigitavelBoleto;

      let parcelaExistente = this.parcelas[i];
      parcela.id = parcelaExistente && parcelaExistente.id !== undefined ? parcelaExistente.id : 0;
      parcela.despesa = parcelaExistente && parcelaExistente.despesa !== undefined ? parcelaExistente.despesa : undefined;
      parcela.despesaId = parcelaExistente && parcelaExistente.despesaId !== undefined ? parcelaExistente.despesaId : 0;
      parcela.parcelaStr = parcelaExistente && parcelaExistente.parcelaStr !== undefined ? parcelaExistente.parcelaStr : '';
      parcela.saldo = parcelaExistente && parcelaExistente.saldo !== undefined ? parcelaExistente.saldo : 0;
      
      this.item.parcelas.push(parcela);
      this.parcela.linhaDigitavelBoleto = "";
    }
     this.valorInicialParcelas = 0; 
    this.numeroParcelas = 0;
  } 
 
  EditarParcela(item) {
    this.parcela = item;
    this.parcela.dataVencimento = new Date(item.dataVencimento).yyyymmdd();    
    this.dialogParcela = true;
  }

  Confirmar(){
    // Obtenha o índice da parcela editada na lista item.parcelas
    let indiceParcelaEditada = this.item.parcelas.indexOf(this.parcela);
    let valorParcelaEditada = this.parcela.valor;

    // Calcule o valor total das parcelas anteriores à parcela editada
    let valorParcelasAnteriores = 0;
    for (let i = 0; i < indiceParcelaEditada; i++) {
      valorParcelasAnteriores += this.item.parcelas[i].valor;
    }
 
    // Calcule o saldo restante após a edição da parcela
    let saldoRestante = this.item.valor - valorParcelasAnteriores - valorParcelaEditada;
    
    // Atualize as parcelas posteriores à parcela editada
    for (let i = indiceParcelaEditada + 1; i < this.item.parcelas.length; i++) {
      let parcela = this.item.parcelas[i];

      let valorFormatado = Number((saldoRestante > 0 ? saldoRestante : 0) / (this.item.parcelas.length - (indiceParcelaEditada + 1))).toFixed(2);
      parcela.valor =  Number(valorFormatado);
    }
    
    let valorTotal = this.item.parcelas.reduce((total, parcela) => total + parcela.valor, 0).toFixed(2);
    this.item.valor = Number(valorTotal);
    this.parcela = new DespesaParcela();
    this.dialogParcela = false;
  }
  
  ExcluirParcela(item) {
      this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => { 
       if(item.id > 0){
          this.service.ExcluirParcela(item.id).then(
            (res) => {
              if (res.status == 200) {
                this.$swal("Aviso", "Registro excluído com sucesso!", "success");
                this.Atualizar();
              }
            },
            (err) => TratarErroApi(err)
          );
        }else{
          const index = this.item.parcelas!.indexOf(item);
          this.item.parcelas!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }
  
  get valorTotal(){
    const valorClassificacoes = this.item.classificacoes.reduce((total, classificacao) => total + classificacao.valor, 0);
    const valorTotal = this.item.valor - valorClassificacoes;
    return Number(valorTotal).toFixed(2);
  }

  AdicionarClassificacao() {
     if (this.$refs.formClassificacao.validate()) {

      let valorRestante = Number(this.valorTotal);

      if (this.classificacaoIndex > -1) {
        const currentClassification = this.item.classificacoes[this.classificacaoIndex];
        valorRestante += currentClassification.valor;
      }

      if (this.classificacao.valor > valorRestante) {
        return this.$swal("Aviso", "O valor não pode ser maior que o valor a ser dividido", "warning");
      }      

      if (this.classificacaoIndex > -1) {
        Object.assign(this.item.classificacoes[this.classificacaoIndex], this.classificacao);
      } else {
        this.item.classificacoes.push(this.classificacao);
      }

      this.classificacao = new DespesaClassificacao();
      this.classificacaoIndex = -1;
    }
  }
  

  ExcluirClassificacao(item) {
      this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
          item.excluido = true;
          const index = this.item.classificacoes!.indexOf(item);
          delete this.item.classificacoes![index]
          this.dialog = false;
          this.dialog = true;
          return true;
        }else{
        const index = this.item.classificacoes!.indexOf(item);
        this.item.classificacoes!.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

  EditarClassificacao(item) {
    this.classificacaoIndex = this.item.classificacoes.indexOf(item);
    this.classificacao = Object.assign({}, item);
  } 
 
  SituacaoColor(item){
    return SituacaoColor(item);
  }

  Atualizar(){
    this.service.ObterPorId(this.item.id, "Parcelas.Baixas, Classificacoes, Documentos").then(
      (res) => {
        this.item = res.data;
        jsonpatch.unobserve(this.item, this.observer);
      }); 
  }

  @Watch("classificacao.valor")
  CalcularPercentualValor(valor){
    if(valor > 0){
      let percentual =  valor * 100 / Number(this.item.valor); 
      this.classificacao.percentual = Number(percentual.toFixed(2));
    }
  }
 
  CalcularValorPercentual(valor){
    let valorClassificado = (this.item.valor * valor) / 100; 
    this.classificacao.valor = Number(valorClassificado.toFixed(2));
  }

  Reset(){
    this.tabActive = 0;
    this.numeroParcelas = 0;
    this.valorInicialParcelas = 0;
    this.classificacaoIndex = -1;
  }

  AtualizarPessoas(){
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      (res) => { this.pessoas = res.data.items })
  }

  ObterEmpreendimentosPorEmpresa(){
    let filter = {};
    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[''],  filter = {empresaId:this.item.empresaId}, '' , 'Id,Nome, EmpresaId', '').then(
      (res) => { this.empreendimentos = res.data.items });
  }

  mounted() {
    this.AtualizarPessoas();
  
    new TipoDocumentoService().ListarTudo().then(
      (res) => {this.tipoDocumentos = res.data.items;},
    );

    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(
      (res) => {this.empresas = res.data.items},
    );

    new TipoDespesaService().ListarTudo().then(
      (res) => {this.tipoDespesas = res.data.items},
    );
  
    new SituacaoDespesaParcelaService().ListarTudo().then(
      (res) => {this.situacoes = res.data.items},
    );
 
    new CentroCustoService().Listagem().then(
      (res) => {this.centroCustosOrdenada = res.data;},
    );

    new ContaGerencialService().Listagem().then(
      (res) => {this.contaGerenciaisOrdenada = res.data},
    );
  }
}
class ModelDespesaPedido{
  despesa  = new Despesa();
  pedidos: Pedido[] = [];
}
