
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { ContratoService, TipoProcessoJudicialService } from "@/core/services/cadastros";
import { Contrato, DocumentoContrato } from "@/core/models/cadastros";
import Indice from "@/core/models/cadastros/Indice";
import { Receita, ReceitaParcela,EnumTipoReceita } from "@/core/models/financeiro";
import { SituacaoColor, TratarErroApi } from '@/assets/scripts/helper';
import { ArquivoService } from "@/core/services/geral/ArquivoService";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CadastroContrato extends Vue {
  @Prop() public item!: Contrato;
  @Prop() public value!: string;

  service = new ContratoService();
  
  parcelaBaixa = new ReceitaParcela();
  documento = new DocumentoContrato();
  contratoDocumento = new Contrato();
  receitaFinanciamento: any = new Financiamento(null);
  receitaEntrada: any = new Financiamento(null);
  receitaAditamento: any = new Financiamento(null);
  receitaServico: any = new Financiamento(null);
  parcela = new ReceitaParcela();

  items:any = [];
  listaTipoProcessos = [];

  dialogBaixaParcela = false;
  overlayDocumentos = false;
  inputDocumento: any = null;
  overlay = false;
  dialog = false;
  subTabActive = null;
  tabActive = 0;
 
  options: any = {
    itemsPerPage: 15
  };

  @Watch("contratoDocumento", {deep:true})
  ContratoDocumento(){
    this.observer = jsonpatch.observe(this.contratoDocumento);
  };

  @Watch("item", {deep:true})
  ObservadorItem(){
   // this.carregarFinanciamentos();
  };
  
  carregarFinanciamentos(){
    if(this.item.receitas.length > 0){ 
      this.receitaFinanciamento = new Financiamento(this.item.receitas.filter(f => f.tipoId == EnumTipoReceita.Titulo)[0])
      this.receitaEntrada = new Financiamento(this.item.receitas.filter(f => f.tipoId == EnumTipoReceita.TituloEntrada)[0])
      this.receitaAditamento = new Financiamento(this.item.receitas.filter(f => f.tipoId == EnumTipoReceita.TituloAditamento)[0])
      this.receitaServico = new Financiamento(this.item.receitas.filter(f => f.tipoId == EnumTipoReceita.Outros)[0])
                                                                                                                                                                                                  
      this.items = [
        {titulo:"Informações do Financiamento", model:{quantidadeParcelas:this.receitaFinanciamento.quantidadeParcelas, valorPrestacao:this.receitaFinanciamento.valorPrestacao, valorAVista:this.receitaFinanciamento.valorAVista,primeiroVencimento:this.receitaFinanciamento.primeiroVencimento, totalPago:this.receitaFinanciamento.totalPago, totalCancelado:this.receitaFinanciamento.totalCancelado}, tabela:this.receitaFinanciamento.receita.parcelas},
        {titulo:"Informações da Entrada", model:{quantidadeParcelas:this.receitaEntrada.quantidadeParcelas,valorPrestacao:this.receitaEntrada.valorPrestacao,valorAVista:this.receitaEntrada.total,primeiroVencimento:this.receitaEntrada.primeiroVencimento, totalPago:this.receitaEntrada.totalPago, totalCancelado:this.receitaEntrada.totalCancelado}, tabela:this.receitaEntrada.receita.parcelas},   
        {titulo:"Informações do Aditamento", model:{quantidadeParcelas:this.receitaAditamento.quantidadeParcelas,valorPrestacao:this.receitaAditamento.valorPrestacao,valorAVista:this.receitaAditamento.total,primeiroVencimento:this.receitaAditamento.primeiroVencimento, totalPago:this.receitaAditamento.totalPago, totalCancelado:this.receitaAditamento.totalCancelado}, tabela:this.receitaAditamento.receita.parcelas},
        {titulo:"Informações do Serviço", model:{quantidadeParcelas:this.receitaServico.quantidadeParcelas,valorPrestacao:this.receitaServico.valorPrestacao,valorAVista:this.receitaServico.total,primeiroVencimento:this.receitaServico.primeiroVencimento, totalPago:this.receitaServico.totalPago, totalCancelado:this.receitaServico.totalCancelado}, tabela:this.receitaServico.receita.parcelas}
      ]
    }
  } 

  headerCorretores: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "Nome", value: "corretorId" },
    { text: "Percentual", value: "percentual" },
  ];

  headers: any[] = [
    {text: "", value: "actions", sortable: false },
    {text: "Nº", value: "parcela", align: "center", sortable: false},
    {text: "Valor", value: "valor", align: "center", sortable: false },
    {text: "Pago", value: "total", align: "center", sortable: false},
    {text: "Saldo", value: "saldo", align: "center", sortable: false},
    {text: "Situação", value: "situacaoId", align: "center", sortable: false},
    {text: "Vencimento", value: "dataVencimento", align: "center", sortable: false},
    {text: "Venc. original", value: "dataVencimentoOriginal", align: "center", sortable: false},
    {text: "Data pgt.", value: "ultimaDataPagamento", align: "center", sortable: false},
    {text: "Forma pgt", value: "ultimaFormaPagamento", align: "center", sortable: false}
  ];

  mesReajuste = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ]; 

  headersDocumentos: any[] = [ 
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome", align: "center",sortable: false },
    { text: "Arquivo", value: "dados", align: "center",sortable: false },
    { text: "Data", value: "dataEmissao", align: "center",sortable: false },
    { text: "Usuário", value: "usuario.login", align: "center",sortable: false },
  ]; 

  headersObservacoes: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Observação", value: "observacao" },
    { text: "Usuário Logado", value: "usuarioId" },
    { text: "Data Hora", value: "dataHora" },
  ];

  observer! : jsonpatch.Observer<Contrato>; 

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 

  Close() {  
    this.Reset();
    this.overlay = false;
    this.dialog = false;
  } 

  BaixarParcela(item){
    this.parcelaBaixa = item;
    this.dialogBaixaParcela = true;
  } 
    
  Reset(){
    this.receitaFinanciamento = new Financiamento(null);
    this.receitaEntrada = new Financiamento(null);
    this.receitaAditamento = new Financiamento(null);
    this.receitaServico = new Financiamento(null);
    this.contratoDocumento = new Contrato();
    this.tabActive = 0;
    this.quantidadeParcelas = 0;
  } 

  SituacaoColor(item:ReceitaParcela){
    return SituacaoColor(item)
  }

  BaixarArquivo(arquivo){
    new ArquivoService().Download(arquivo);
  }

  CompartilharArquivo(item){
    new ArquivoService().Compartilhar(item);
  }

  getDocumentos(atualizar = false){
    if(this.contratoDocumento.documentos.length == 0 || atualizar){
    this.overlayDocumentos = true;

    this.service.ObterPorId(this.item.id, "Documentos.Usuario").then((res) => {
      this.contratoDocumento = res.data;
      this.overlayDocumentos = false;
    });  
    } 
  }

  Salvar() {
    this.overlay = !this.overlay;
    this.observer = jsonpatch.observe(this.item);
    let pacthModel = jsonpatch.generate(this.observer);

    this.service.Patch(pacthModel, this.item.id).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        jsonpatch.unobserve(this.item, this.observer);
        this.Close();
     },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
     }).finally(() => {this.overlay = false});
  }

  Cancelar(){
    this.overlay = !this.overlay;

    this.service.CancelarProposta(this.item.id).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
     }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
     }).finally(() => {this.overlay = false});
  }
   
  dialogAprovar:boolean = false;
  quantidadeParcelas:number = 0;

  Aprovar(){     
    this.service.AprovarProposta(this.item.id, this.item, this.quantidadeParcelas).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.quantidadeParcelas = 0;
        this.Close();
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    );  
  }

 
  SalvarDoc(){
    this.observer = jsonpatch.observe(this.contratoDocumento);
    let pacthModel = jsonpatch.generate(this.observer);

    this.service.Patch(pacthModel, this.contratoDocumento.id).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");

        jsonpatch.unobserve(this.contratoDocumento, this.observer);
        this.getDocumentos(true);
     },
      (err) => TratarErroApi(err)
    );
  }

  async AdicionarArquivo() {
    if (!this.inputDocumento) {
      this.$swal("Aviso", "É necessário selecionar um arquivo.", "warning");
      return;
    } 
    let dados = await new ArquivoService().Ler(this.inputDocumento);
    this.documento.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
 
    this.documento.dados = dados.replace(/^[^,]*,/, "");
    this.documento.nome = this.documento.nome != "" ? this.documento.nome : this.inputDocumento.name;
    this.documento.tamanho = this.inputDocumento.size;
    this.documento.tipo = this.inputDocumento.type;
    this.documento.dataEmissao = new Date().yyyymmdd();
    this.contratoDocumento.documentos.push(this.documento);   
    this.documento = new DocumentoContrato();
    this.inputDocumento = null;
    this.SalvarDoc();
  }

  ExcluirDocumento(documento:any) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(documento.id > 0){
         documento.excluido = true;
         const index = this.contratoDocumento.documentos!.indexOf(documento);
         delete this.contratoDocumento.documentos![index]
         this.dialog = false;
         this.dialog = true;
         return true; 
        }else{
        const index = this.contratoDocumento.documentos!.indexOf(documento);
        this.contratoDocumento.documentos!.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
        this.SalvarDoc();
      }
    })
  }

  

  empresas = [];

  mounted(){
    new TipoProcessoJudicialService().ListarTudo().then(
      res => {this.listaTipoProcessos = res.data.items})

    new EmpresaService().ListarTudo().then(
      res => {
        this.empresas = res.data.items;
      }
    )
  }
}
 class Financiamento{
  public quantidadeParcelas: number = 0;
  public valorPrestacao: string = '';
  public valorAVista: string = '';
  public total: number = 0;
  public primeiroVencimento: string = '';
  public totalPago:number= 0;
  public totalCancelado:number = 0;
  public indice: Indice = new Indice();
  public receita?: Receita; 

  constructor(receita: any, indice: Indice = new Indice()){
    if(receita != null){       
      this.receita = receita;
      this.quantidadeParcelas = receita.quantidadeParcela != undefined ? receita.quantidadeParcela : 0;
      this.valorPrestacao = receita.valorParcelaAtual;
      this.valorAVista = receita.valor;
      this.totalPago = receita.totalPagoParcelas;
      this.totalCancelado = receita.totalCanceladoParcelas;
      this.total = receita.valor 
      this.primeiroVencimento = receita.primeiroVencimento;
      this.indice = indice;
    }else{
      this.receita = new Receita(); 
    }
  }
}
