
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { OrcamentoEtapa, OrcamentoObras} from "@/core/models/orcamentoObras";
import { ClasseComposicaoService, ComposicaoService, OrcamentoEtapaItemService, OrcamentoEtapaService, OrcamentoObrasService, OrigemDadosService, TipoComposicaoService } from "@/core/services/orcamentoObras";
import { EmpreendimentoService} from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { EnderecoService } from "@/core/services/geral/EnderecoService";
import { UnidadeMedidaService } from "@/core/services/almoxarifado";
import OrcamentoEtapaItem from "@/core/models/orcamentoObras/OrcamentoEtapaItem";

@Component
export default class CadastroOrcamento extends Vue {
  @Prop() public item!: OrcamentoObras;
  @Prop() public value!: string; 

  itemOrcamento = new OrcamentoObras();
  orcamentoEtapa = new OrcamentoEtapa();
  orcamentoEtapaItem = new OrcamentoEtapaItem();

  service = new OrcamentoObrasService();
  orcamentoEtapaService = new OrcamentoEtapaService();
  orcamentoEtapaItemService = new OrcamentoEtapaItemService();
  composicaoService = new ComposicaoService();

  expandedItems:any = []; 

  listaEmpresas = [];
  listaEmpreendimentos = [];
  listaOrigemDados = [];
  listaEstados = [];
  expanded:any = [];
  expandedComposicao = [];
  listaClassesComposicao = [];
  unidadeMedidas = [];
  tiposComposicao = [];
  listaDatas = [];

  composicoesAdicionadas:any = [];
  composicoesPesquisada = [];

  etapas:any = [];
  titulo:string ="";
  tab:number = 0;
  tabComposicao:number = 0;
   
  dialog:boolean = false;
  dialogEtapa:boolean = false;
  dialogSubEtapa:boolean = false;
  dialogComposicao:boolean = false;
  dialogEditarEtapa:boolean = false;
  dialogEditarEtapaItem:boolean = false;
  errorAlert:boolean = false;
  isExpanded:boolean = false;
  valid:boolean = true;
  loading: boolean = true;
  validOrcamentoEtapa:boolean = true;
   
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
    formOrcamentoEtapa: HTMLFormElement;
  }; 

  optionsComposicoes: any = {
    itemsPerPage: 15
  };

  filterComposicao = {origemId: 0, classeId:0, tipoId:0, unidadeId:0, descricao:'' };

  headersComposicoes = [
    { text: '', value: 'data-table-expand', sortable: false },
    { text: 'Base',value: 'composicao.origem.nome', sortable: false},
    { text: 'Cód.', value: 'composicao.codigo', sortable: false },
    { text: 'Descrição', value: 'composicao.descricao', sortable: false },
    { text: 'Unidade', value: 'composicao.unidade.nome', sortable: false },
    { text: 'Total Valor Desonerado', value: 'valorDesonerado', sortable: false },
    { text: 'Total Valor Não Desonerado', value: 'valorNaoDesonerado', sortable: false },
    { text: 'Classe', value: 'composicao.classe.nome', sortable: false },
    { text: 'Tipo', value: 'composicao.tipo.nome', sortable: false },
    { text: 'Ações', value: 'actions', sortable: false },
  ];  

  headersComposicoesEtapaItens = [
    { text: 'Base',value: 'composicao.origem.nome', sortable: false},
    { text: 'Cód.', value: 'composicao.codigo', sortable: false },
    { text: 'Descrição', value: 'composicao.descricao', sortable: false },
    { text: 'Unidade', value: 'composicao.unidade.nome', sortable: false },
    { text: 'Quantidade', value: 'composicao.quantidade', sortable: false },
    { text: 'Total Valor Desonerado', value: 'valorDesonerado', sortable: false },
    { text: 'Total Valor Não Desonerado', value: 'valorNaoDesonerado', sortable: false },
    { text: 'Classe', value: 'composicao.classe.sigla', sortable: false },
    { text: 'Tipo', value: 'composicao.tipo.sigla', sortable: false },
    { text: 'Ações', value: 'actions', sortable: false },
  ]

  headersEtapas = [ 
    { text: 'Nome da Etapa', value: 'titulo', sortable: false },
    { text: 'Total', value: 'valorTotalDesonerado', sortable: false },
    { text: 'Total BDI', value : 'valorTotalBDI', sortable: false},
    { text: 'Quantidade', value: 'quantidade', sortable: false },
    { text: '', value: 'actions', sortable: false}
  ];

  @Watch("itemOrcamento")
  ItemOrcamento() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }

  @Watch("orcamentoEtapa")
  OrcamentoEtapa() {
    if (this.$refs.formOrcamentoEtapa) {
      this.$refs.formOrcamentoEtapa.resetValidation(); 
    } 
  }

  observer!: jsonpatch.Observer<OrcamentoObras>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
  }
 
  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.itemOrcamento = this.item;
    }
  } 

  ValidarCampos(){
    if(!this.itemOrcamento.descricao || !this.itemOrcamento.empresaId || !this.itemOrcamento.empreendimentoId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tab = 0;
      return true;
    }

     if(!this.itemOrcamento.referencia || !this.itemOrcamento.estadoId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tab = 1;
      return true;
    }
  }
  
  Salvar() {
    this.$refs.form.validate();

    if (!this.ValidarCampos()) {
      this.observer = jsonpatch.observe(this.itemOrcamento);
      let pacthModel = jsonpatch.generate(this.observer);
      
        (this.itemOrcamento.id > 0 ? this.service.Patch(pacthModel, this.itemOrcamento.id) : this.service.Salvar(this.itemOrcamento)).then(
          (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.Atualizar(res.data.id);
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          } 
        }
      )
    }
  }

  Close() {
    this.dialog = false;
  }

  CloseDialogComposicao(){
    this.orcamentoEtapa = new OrcamentoEtapa();
    this.dialogComposicao = false;
    this.composicoesPesquisada = [];
  }

  AtualizarEstado(){
    if(this.itemOrcamento.id > 0){
      this.observer = jsonpatch.observe(this.itemOrcamento);
      let pacthModel = jsonpatch.generate(this.observer);
        
      this.service.Patch(pacthModel, this.itemOrcamento.id).then(
        (res) => {
          this.Atualizar(res.data.id);
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          } 
        }
      )
    }
  }

  async PesquisarComposicao(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.optionsComposicoes; 
    this.loading = true;

    await this.composicaoService.ListarComposicoesValoresAtualizados(this.filterComposicao, this.item.estadoId, this.item.referencia,page, itemsPerPage, sortBy, sortDesc,search, columns, this.filterComposicao, 'Origem, Unidade, Itens.Insumo.Tipo, Itens.Insumo.Unidade, Itens.Insumo.Origem, Tipo, Classe').then(
      (res) => {
        this.composicoesPesquisada = res.data;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }
    ).finally(() => this.loading = false); 
  }

  OpenDialogComposicao(item){
    this.orcamentoEtapa = item;
    this.dialogComposicao = true;
    this.PesquisarComposicao();
  }

  PrepararComposicoes(){
    if(this.composicoesAdicionadas.length == 0){
      this.$swal("Aviso","É obrigatório adicionar os insumos","warning");
      return;
    };

      this.composicoesAdicionadas.forEach(item => {
      const itemExistente = this.orcamentoEtapa.itens.find(etapa => etapa.composicaoId === item.composicao.id);
    
      if(itemExistente){
       // console.log(`O insumo com ID ${item.composicao} já foi adicionado.`);
      }else{
        let etapaItem = new OrcamentoEtapaItem();
          etapaItem.etapaId = this.orcamentoEtapa.id;
          etapaItem.composicaoId = item.composicao.id;
          etapaItem.composicao = item.composicao;
          etapaItem.quantidade = item.composicao.quantidade;
          this.orcamentoEtapa.itens.push(etapaItem);
        } 
    });

    this.orcamentoEtapa = new OrcamentoEtapa();
    this.composicoesAdicionadas = [];
    this.dialogComposicao = false;
    this.Salvar();     
    //return this.$swal("Aviso","Item adicionado com sucesso!","success");
  }

  AdicionarComposicao(item){
      
    const composicaoExistente = this.composicoesAdicionadas.find(x => x.composicao.id === item.composicao.id);
    if(composicaoExistente){
      this.$swal("Aviso","Esse item já foi adicionado na lista!","warning");
      return;
    };

    this.composicoesAdicionadas.push(item);
    this.errorAlert = true;
  }

  ExcluirComposicaoPesquisado(item){
    let indiceItem = this.composicoesAdicionadas.indexOf(item);
    this.composicoesAdicionadas.splice(indiceItem, 1);
  }

 /* ValorTotalEtapa(item: OrcamentoEtapa, desonerado: boolean) :number
  {
    let valorTotal = 0;
    if(desonerado)
    {
      valorTotal = item.valorTotalDesonerado; 
      if(item.children.length > 0)
      {
        item.children.forEach(child => {
          valorTotal += this.ValorTotalEtapa(child, desonerado)
        });
      }
    }
    else
    {
      valorTotal = item.valorTotalNaoDesonerado; 
      if(item.children.length > 0)
      {
        item.children.forEach(child => {
          valorTotal += this.ValorTotalEtapa(child, desonerado)
        });
      }
    }
    
    return valorTotal;
  }*/

  AdicionarEtapa(close){
    if (this.$refs.formOrcamentoEtapa.validate()) {
      if(this.orcamentoEtapa.titulo.length > 0){

        let orcamentoEtapa = new OrcamentoEtapa();
        orcamentoEtapa.id = 0;
        orcamentoEtapa.etapaPaiId = null;
        orcamentoEtapa.titulo = this.orcamentoEtapa.titulo;
        orcamentoEtapa.descricao = this.orcamentoEtapa.descricao;
        orcamentoEtapa.quantidade = this.orcamentoEtapa.quantidade;
        this.itemOrcamento.etapas.push(orcamentoEtapa);
      }
      this.orcamentoEtapa = new OrcamentoEtapa();
      this.Salvar();
    }

    if(close){
      this.dialogEtapa = false;
    }
  }

  ExcluirOrcamentoEtapa(item){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true, 
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      if(item.id){
        this.orcamentoEtapaService.Excluir(item.id).then(
          (res) => {
            this.$swal("Aviso", res.data, "success");
            this.Atualizar(this.orcamentoEtapa.id);
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            } 
          }
        )
       }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
      if(result.value){

      }
    })
  }

  SalvarSubEtapa(close){
    let etapaItem = new OrcamentoEtapa();
      etapaItem.etapaPaiId = this.orcamentoEtapa.id;
      etapaItem.titulo = this.titulo;

    if(etapaItem.titulo.length > 0){
      this.orcamentoEtapa.children.push(etapaItem);
      this.itemOrcamento.etapas.push(etapaItem);
      this.Salvar();
    }
    
    this.orcamentoEtapa = new OrcamentoEtapa();
    this.titulo = '';

    if(close){
      this.dialogSubEtapa = false;
    }
  }

  OpenDialogSubEtapa(item){
    this.orcamentoEtapa = item;
    this.dialogSubEtapa = true;
  }

  CloseDialogSubEtapa(){
    this.orcamentoEtapa = new OrcamentoEtapa();
    this.dialogSubEtapa = false;
  }

  SalvarEtapa(){
    this.orcamentoEtapaService.Salvar(this.orcamentoEtapa).then(
      (res) => {
      this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
      this.Atualizar(res.data.id);
      this.CloseDialogEditarEtapa();
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      } 
    })
  } 

  OpenDialogEditarEtapa(item){
    this.orcamentoEtapa = item;
    this.dialogEditarEtapa = true;
  }

  CloseDialogEditarEtapa(){
    this.orcamentoEtapa = new OrcamentoEtapa();
    this.dialogEditarEtapa = false;
  }

  //Etapa Item
  OpenDialogEditarEtapaItem(etapaItem){
    this.orcamentoEtapaItem = etapaItem;
    this.dialogEditarEtapaItem = true;
  }

  CloseDialogEditarEtapaItem()
  {
    this.orcamentoEtapaItem = new OrcamentoEtapaItem();
    this.dialogEditarEtapaItem = false;
  }

  SalvarEtapaItem(){
    this.orcamentoEtapaItemService.Salvar(this.orcamentoEtapaItem).then(
      (res) => {
      this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
      this.Atualizar(res.data.id);
      this.CloseDialogEditarEtapa();
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      } 
    })
  }

  Atualizar(id) {
    if (this.observer) {
      jsonpatch.unobserve(this.itemOrcamento, this.observer);
    }

    this.service.ObterPorId(id ?? this.itemOrcamento.id, 'Estado, Etapas.Itens.Composicao,Etapas.Itens.Composicao.Tipo,Etapas.Itens.Composicao.Classe, Etapas.Itens.Composicao.Unidade, Etapas.Itens.Composicao.Itens.Insumo ').then(
      (res) => {
        this.itemOrcamento = res.data;
        this.observer = jsonpatch.observe(this.itemOrcamento);
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error"); 
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }
    );
  }

  @Watch('itemOrcamento.etapas')
  ObterEtapasChildren(etapas) {
    if (etapas == null) return;

    const mapaEtapas = new Map();
   
   etapas.forEach((etapa) => 
    {
        const { id, etapaPaiId } = etapa;

        if (!mapaEtapas.has(id)) 
        {
          mapaEtapas.set(id, { ...etapa, children: [] });
        }

        if (etapaPaiId !== null) 
        {
          if (!mapaEtapas.has(etapaPaiId)) {
            mapaEtapas.set(etapaPaiId, { children: [] });
          }
          mapaEtapas.get(etapaPaiId).children.push(mapaEtapas.get(id));
        }
      }
    );

    // Aplicar cálculo após a construção completa do mapa
    mapaEtapas.forEach((etapa) => {
      this.calcularValorTotalDesonerado(etapa);
    });

  
    // valortotalitemDesonerado * o (bdi do orcamento / 100)
    this.etapas = Array.from(mapaEtapas.values()).filter((item) => item.etapaPaiId === null || item.etapaPaiId == undefined);
  }

  calcularValorTotalDesonerado(etapa) {
    if (!etapa.itens) {
      etapa.itens = [];
    }

    const valorDesoneradoDireto = etapa.itens.reduce((total, item) => total + Number(item.valorTotalItemDesonerado), 0) * etapa.quantidade;
    const valorDesoneradoFilhos = etapa.children.reduce((total, child) => total + this.calcularValorTotalDesonerado(child), 0);
    etapa.valorTotalDesonerado = valorDesoneradoDireto + valorDesoneradoFilhos;
     
    return etapa.valorTotalDesonerado;
  }

  toggleExpandAll() {
    if (this.expanded.length > 0) {
      this.expanded = [];
    } else {
      this.expanded = this.etapas.map((subItem) => subItem);
    }
  }

  expandEtapa(item) {
    const index = this.expanded.findIndex((etapa) => etapa.id === item.id);

    if (index !== -1) {
      this.expanded.splice(index, 1);
    } else {
      this.expanded.push(item);
    }
  }

  @Watch("dialog")
  ObterEmpreendimentos(){
    let filter = {empresaId:this.itemOrcamento.empresaId};
    
    if(this.dialog){
      const empreendimentoService =  new EmpreendimentoService();
      empreendimentoService.Listar(-1, -1, ['nome'],[],'',[], filter, '' , 'Id, nome, empresaId', '').then(
        (res) => {
          this.listaEmpreendimentos = res.data.items;
        }
      )
    }
  }

  obterValorEncargos(itemOrcamento, encargosDesonerados) {
    return encargosDesonerados ? itemOrcamento.valorTotalDesonerado : itemOrcamento.valorTotalNaoDesonerado;
  }

  obterValorBDI(itemOrcamento, encargosDesonerados) {
    return encargosDesonerados ? itemOrcamento.valorDesoneradoBDI : itemOrcamento.valorNaoDesoneradoBDI;
  }

  obterValorTotalComBDI(itemOrcamento, encargosDesonerados) {
    return encargosDesonerados ? itemOrcamento.valorTotalDesoneradoComBDI : itemOrcamento.valorTotalNaoDesoneradoComBDI;
  }

  get allExpanded() {
    return this.expanded.length === this.etapas.length;
  }

  get validacaoOrcamento() {
    return !this.itemOrcamento.descricao || !this.itemOrcamento.empresaId || !this.itemOrcamento.empreendimentoId || !this.itemOrcamento.origemId || !this.itemOrcamento.referencia || !this.itemOrcamento.estadoId;
  }
 
  get isOrcamentoBloqueado() {
    return this.validacaoOrcamento;
  }

  mounted(){
    const datasService = new ComposicaoService();
    datasService.ObterDatas().then(
      (res) => {
        this.listaDatas = res.data;
      }
    )

    const estadoService = new EnderecoService();
    estadoService.Listar(1,-1, ['nome'], [],undefined, [], '', '', '', '').then(
      (res) => {
        this.listaEstados = res.data.items
      } 
    )  

    const empresaService =  new EmpresaService();
    empresaService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,nomeFantasia', '').then(
      (res) => {
        this.listaEmpresas = res.data.items;
      }
    )

    const origemService =  new OrigemDadosService();
    origemService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id ,nome', '').then(
      (res) => {
        this.listaOrigemDados = res.data.items;
      }
    )

    const unidadeMedidaService = new UnidadeMedidaService();
    unidadeMedidaService.ListarTudo().then(
      (res) => {
        this.unidadeMedidas = res.data.items;
      } 
    )

    const tipoComposicaoService = new TipoComposicaoService();
    tipoComposicaoService.ListarTudo().then(
      (res) => {
        this.tiposComposicao = res.data.items;
      } 
    )

    const classeComposicaoService = new ClasseComposicaoService();
    classeComposicaoService.ListarTudo().then(
      (res) => {
        this.listaClassesComposicao = res.data.items;
      } 
    )
  }
}
